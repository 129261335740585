import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import React, {useEffect, useState} from 'react'
import './App.css';
import moment from 'moment'


const bull = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
  >
    •
  </Box>
);


const card5 = (
  <React.Fragment>
    <CardContent>
    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
        Satz des Tages
    </Typography>
    <Typography variant="h5" component="div">
        Schau mir in die Augen und sag das noch mal.
      </Typography>
      <Typography sx={{ mb: 1.5 }} color="text.secondary">
        Überzeugungsmethode
      </Typography>
      <Typography variant="body2">
        Diese Augen können einen in die Knie zwingen.
        <br />
        {'"Deine Augen... Darin könnte ich mich verlieren"'}
      </Typography>
    </CardContent>
  </React.Fragment>
);

const card4 = (
  <React.Fragment>
    <CardContent>
    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
        Word of the Day
    </Typography>
    <Typography variant="h5" component="div">
        Zu Hause
      </Typography>
      <Typography sx={{ mb: 1.5 }} color="text.secondary">
        Beschreibung
      </Typography>
      <Typography variant="body2">
        Zu Hause ist kein Ort, es ist eine Person.
        <br />
        {'"Du bist mein zu Hause."'}
      </Typography>
    </CardContent>
  </React.Fragment>
);

const card3 = (
  <React.Fragment>
    <CardContent>
    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
        Word of the Day
    </Typography>
    <Typography variant="h5" component="div">
        Wild
      </Typography>
      <Typography sx={{ mb: 1.5 }} color="text.secondary">
        Adjektiv
      </Typography>
      <Typography variant="body2">
        Unbegreiflich - Verrückt - Unglaublich
        <br />
        {'"Wild! | Das ist ja wild!"'}
      </Typography>
    </CardContent>
  </React.Fragment>
);

const card2 = (
  <React.Fragment>
    <CardContent>
    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
        Word of the Day
    </Typography>
    <Typography variant="h5" component="div">
        Un{bull}fucking{bull}fassbar
      </Typography>
      <Typography sx={{ mb: 1.5 }} color="text.secondary">
        Adjektiv
      </Typography>
      <Typography variant="body2">
        Verrückt - Wild
        <br />
        {'"Es ist unfuckingfassbar!"'}
      </Typography>
    </CardContent>
  </React.Fragment>
);

const card = (
  <React.Fragment>
    <CardContent>
    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
        Person of the Day
    </Typography>
    <Typography variant="h5" component="div">
        Lo{bull}re{bull}na
      </Typography>
      <Typography sx={{ mb: 1.5 }} color="text.secondary">
        Very Important Person
      </Typography>
      <Typography variant="body2">
        well meaning and kind and the most intoxicating smile.
        <br />
        {'"This is Lorena, she is my world"'}
      </Typography>
    </CardContent>
  </React.Fragment>
)

const countdown =  (days, hours, mins, seconds) => {
  // const diff = moment().diff(target, 'seconds') * -1 
  // const days = moment().diff(target, 'days') * -1
  // const hours = Math.floor(diff/3600)-(days*24)
  // const mins = Math.floor(diff/60) - ((moment().diff(target, 'hours') * 60) * -1)
  // const seconds = Math.floor(diff) - ((moment().diff(target, 'minutes') * 60) * -1)

  return   (
    <React.Fragment>
      <CardContent>
      <Typography variant="h5" component="div">
          {days}d - {hours}h - {mins}m - {seconds}s
      </Typography>
      </CardContent>
    </React.Fragment>
  )
}

const getCard = (num) => {
  switch (num) {
    case 1:
      return card;
    case 2:
      return card2;
    case 3: 
      return card3;
    case 4:
      return card4;
    case 5:
      return card5;
    default:
      return card;
  } 
}

function App() {
  const [target, setTarget] = useState(moment("2024-04-28 14-00-00", "YYYY-MM-DD HH-mm-ss"))
  const [seconds, setSeconds] = useState(0)
  const [minutes, setMinutes] = useState(0)
  const [hours, setHours] = useState(0)
  const [days, setDays] = useState(0)
  const [reload, setReload] = useState(false)
  const [num, setNum] = useState(-1)

  useEffect(() => {
    if (num === -1 ) {
      setNum((Math.floor(Math.random() * 1000)%5) + 1)
    }
    const interval = setInterval(() => {
      const diff = moment().diff(target, 'seconds') * -1 
      const days = moment().diff(target, 'days') * -1
      const hours = Math.floor(diff/3600)-(days*24)
      const mins = Math.floor(diff/60) - ((moment().diff(target, 'hours') * 60) * -1)
      const seconds = Math.floor(diff) - ((moment().diff(target, 'minutes') * 60) * -1)
      setSeconds(seconds)
      setMinutes(mins)
      setHours(hours)
      setDays(days)
    }, 1000)
    return () => clearInterval(interval)
  }, [reload, num, target])

  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="sm" style={{marginTop: '3em'}}>
      <Box sx={{ minWidth: 275 }}>
        <Card variant="outlined" >{getCard(num)}</Card>
      </Box>
      <Box sx={{ minWidth: 275 }}>
        <Card variant="outlined" >{countdown(days, hours, minutes, seconds)}</Card>
      </Box>
      <br />
      <button type='button' onClick={() => {
        setNum((Math.floor(Math.random() * 1000)%5) + 1)
      }}>Next</button>
      </Container>
      
    </React.Fragment>
  );
}

export default App;
